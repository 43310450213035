import React from "react";

const NoPage = () => {
    return(
        <div>
           <h2>No page found</h2>
        </div>
    )
}

export default NoPage;