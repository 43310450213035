import {
    Flex,
    Container,
    Heading,
    Stack,
    Text,
    Button,
} from '@chakra-ui/react';
import React from "react";
import ArchitecturalDesignCarousel from "../ArchitecturalDesignCarousel/ArchitecturalDesignCarousel";
import {useNavigate} from "react-router-dom";

export default function ArchitecturalDetails() {

    const navigate = useNavigate();

    const handleClick = () => navigate('/architectural-services-getting-started');

    const viewPortfolio = () => navigate('/architectural-portfolio');


    return (
        <Container maxW={'5xl'}>
            <Stack
                textAlign={'center'}
                align={'center'}
                spacing={{ base: 8, md: 10 }}
                py={{ base: 20, md: 28 }}>
                <Heading
                    fontWeight={600}
                    fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                    lineHeight={'110%'}>
                    Architectural Design{' '}
                    <Text as={'span'} color={'orange.400'}>
                        expertise
                    </Text>
                </Heading>
                <Text color={'gray.750'} maxW={'3xl'}>
                    The Platforum Architectural team will deliver designs for your new construction project, alteration,
                        or redevelopment at an affordable cost.
                </Text>
                <Stack spacing={6} direction={'row'}>
                    <Button
                        rounded={'full'}
                        px={6}
                        colorScheme={'orange'}
                        bg={'orange.400'}
                        _hover={{ bg: 'orange.500' }}
                        onClick={() => handleClick()}
                    >
                        Get started
                    </Button>
                    <Button rounded={'full'} px={6}
                            onClick={() => viewPortfolio()}>
                        View Portfolio
                    </Button>
                </Stack>
                <Flex>
                    <ArchitecturalDesignCarousel/>
                </Flex>
            </Stack>
        </Container>
    );
}
