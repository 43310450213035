import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import ArchitecturalServicesNavBar from "../../Components/ArchitectualServicesNavBar/ArchitecturalServicesNavBar";
import Footer from "../../Components/Footer/Footer";
import ArchitecturalPortfolioCarousel
    from "../../Components/ArchitecturalPortfolioCarousel/ArchitecturalPortfolioCarousel";

const DesignPortfolio = () => {
    return(
        <ChakraProvider>
            <ArchitecturalServicesNavBar/>
            <ArchitecturalPortfolioCarousel/>
            <Footer/>
        </ChakraProvider>
    )
}

export default DesignPortfolio;