import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import SignUp from "../../Components/SignUp/SignUp";
import Footer from "../../Components/Footer/Footer";
import ArchitecturalServicesNavBar from "../../Components/ArchitectualServicesNavBar/ArchitecturalServicesNavBar";

const ArchitecturalGettingStarted = () => {
    return (
        <ChakraProvider>
            <ArchitecturalServicesNavBar/>
            <SignUp signupType={"architectural"}/>
            <Footer/>
        </ChakraProvider>
    )
}

export default ArchitecturalGettingStarted;