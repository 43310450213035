import { ChakraProvider } from "@chakra-ui/react";
import FinancialServicesNavBar from "../../Components/FinancialServicesNavBar/FinancialServicesNavBar";
import FinancialDetails from "../../Components/FinancialDetails/FinancialDetails";
import FinancialTestimonial from "../../Components/FinancialTestimonial/FinancialTestimonial";
import React from "react";
import FinancialFeatures from "../../Components/FinancialFeatures/FinancialFeatures";
import Footer from "../../Components/Footer/Footer";
import FinancialStats from "../../Components/FinancialStats/FinancialStats";
import FinancialServicesCallToAction from "../../Components/FinancialCallToAction/FinancialCallToAction";

const AccountingHome = () => {
    return (
        <ChakraProvider>
            <FinancialServicesNavBar/>
            <FinancialDetails/>
            <FinancialFeatures/>
            <FinancialTestimonial/>
            <FinancialServicesCallToAction/>
            <FinancialStats/>
            <Footer/>
        </ChakraProvider>
    )
}

export default AccountingHome;