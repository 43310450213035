import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import ArchitecturalDetails from "../../Components/ArchitecturalDetails/ArchitecturalDetails";
import ArchitecturalServicesNavBar from "../../Components/ArchitectualServicesNavBar/ArchitecturalServicesNavBar";
import ArchitecturalTestimonial from "../../Components/ArchitecturalTestimonial/ArchitecturalTestimonial";
import Footer from "../../Components/Footer/Footer";
import image from "../../images/archBuilding.jpg";


const DesignHome = () => {
    return(
        <ChakraProvider>
            <div style={{ backgroundImage:`url(${image})`, backgroundColor: `rgba(255,255,255,0.8)`, backgroundBlendMode: `lighten` }}>
            <ArchitecturalServicesNavBar/>
            <ArchitecturalDetails/>
            <ArchitecturalTestimonial/>
            <Footer/>
            </div>
        </ChakraProvider>
    )
}

export default DesignHome;