import {
    Container,
    Heading,
    Stack,
    Text,
    Button,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import image from "../../images/workers.jpg";
import React from "react";

export default function FinancialDetails() {

    const navigate = useNavigate();

    const handleClick = () => navigate('/financial-services-getting-started');

    const handleMoreInformation = () => navigate('/financial-services-welcome');

    return (
        <Container maxW={'5xl'} style={{
            backgroundImage:`url(${image})`,
            backgroundColor: `rgba(255,255,255,0.30)`,
            backgroundBlendMode: `lighten`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '100vw',
            height: '50vh',
        }}
        >
            <Stack
                textAlign={'center'}
                align={'center'}
                spacing={{ base: 8, md: 10 }}
                py={{ base: 20, md: 28 }}>
                <Heading
                    fontWeight={800}
                    fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                    lineHeight={'110%'}>
                    Bookkeeping{' '}
                    <Text as={'span'} color={'orange.500'}>
                        made easy
                    </Text>
                </Heading>
                <Text fontWeight='800' fontSize='20px' as='b' maxW={'3xl'}>
                    Platforum Outsourcing offers bookkeeping and financial services that let you focus on what your
                        business does best.
                </Text>
                <Stack spacing={6} direction={'row'}>
                    <Button
                        rounded={'full'}
                        px={6}
                        colorScheme={'orange'}
                        bg={'orange.500'}
                        _hover={{ bg: 'orange.500' }}
                        onClick={() => handleClick()}
                    >
                        Get started
                    </Button>
                    <Button rounded={'full'} px={6}
                            onClick={() => handleMoreInformation()}>
                        Learn more
                    </Button>
                </Stack>
            </Stack>
        </Container>
    );
}

