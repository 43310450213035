import React from "react";
import { Outlet } from "react-router-dom"

const Layout = () => {
    return <>
        <span></span>
        <Outlet />
    </>
}

export default Layout;