import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AccountingHome from "./pages/Accounting/AccountingHome";
import DesignHome from "./pages/ArchitecturalDesign/DesignHome";
import GetInContact from "./pages/Contact/getInContact";
import NoPage from "./pages/General/noPage";
import Layout from "./pages/General/layout";
import FinancialProducts from "./pages/Accounting/FinancialProducts";
import FinancialGettingStarted from "./pages/Accounting/FinancialGettingStarted";
import DesignPortfolio from "./pages/ArchitecturalDesign/DesignPortfolio";
import FinancialPortfolio from "./pages/Accounting/FinancialServicesPortfolio";
import ArchitecturalGettingStarted from "./pages/ArchitecturalDesign/ArchitecturalGettingStarted";
import FinancialServicesCallToAction from "./pages/Accounting/FinancialServicesCallToAction";

const App: React.FC = () => {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Layout />}>
                  <Route index element={<AccountingHome />} />
                  <Route path="architectural-design" element={<DesignHome />} />
                  <Route path="architectural-portfolio" element={<DesignPortfolio />} />
                  <Route path="architectural-services-getting-started" element={<ArchitecturalGettingStarted />} />
                  <Route path="contact" element={<GetInContact />} />
                  <Route path="financial-services-portfolio" element={<FinancialPortfolio />}/>
                  <Route path="financial-product-offerings" element={<FinancialProducts />} />
                  <Route path="financial-services-getting-started" element={<FinancialGettingStarted />} />
                  <Route path="financial-services-welcome" element={<FinancialServicesCallToAction />} />
                  <Route path="*" element={<NoPage />} />
              </Route>
          </Routes>
      </BrowserRouter>
  );
}

export default App;
