import { ChakraProvider } from "@chakra-ui/react";
import FinancialServicesNavBar from "../../Components/FinancialServicesNavBar/FinancialServicesNavBar";
import React from "react";
import Footer from "../../Components/Footer/Footer";
import FinancialPortfolioCarousel from "../../Components/FinancialPortfolioCarousel/FinancialPortfolioCarousel";

const FinancialPortfolio = () => {
    return (
        <ChakraProvider>
                <FinancialServicesNavBar/>
                <FinancialPortfolioCarousel/>
                <Footer/>
        </ChakraProvider>
    )
}

export default FinancialPortfolio;