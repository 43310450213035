import { ChakraProvider } from "@chakra-ui/react";
import FinancialServicesNavBar from "../../Components/FinancialServicesNavBar/FinancialServicesNavBar";
import FinancialDetails from "../../Components/FinancialDetails/FinancialDetails";
import FinancialTestimonial from "../../Components/FinancialTestimonial/FinancialTestimonial";
import React from "react";

const GetInContact = () => {
    return(
        <ChakraProvider>
            <FinancialServicesNavBar/>
            <FinancialDetails/>
            <FinancialTestimonial/>
        </ChakraProvider>
    )
}

export default GetInContact;