import { ChakraProvider } from "@chakra-ui/react";
import FinancialServicesNavBar from "../../Components/FinancialServicesNavBar/FinancialServicesNavBar";
import React from "react";
import FinancialPortfolioCarousel from "../../Components/FinancialPortfolioCarousel/FinancialPortfolioCarousel";
import Footer from "../../Components/Footer/Footer";

const FinancialProducts = () => {
    return(
        <ChakraProvider>
            <FinancialServicesNavBar/>
            <FinancialPortfolioCarousel productOnly={true}/>
            <Footer/>
        </ChakraProvider>
    )
}

export default FinancialProducts;