import React, {useState} from "react";
import {Box, Flex, Image, Text} from "@chakra-ui/react";
import title from '../../images/Accounting and Bookkeeping Services/1.Title.PNG';
import aboutUs from '../../images/Accounting and Bookkeeping Services/2.AboutUs.PNG';
import serviceOfferings from '../../images/Accounting and Bookkeeping Services/3.ServiceOfferings.PNG';
import whyOutsource from '../../images/Accounting and Bookkeeping Services/4.WhyOutsource.PNG';
import benefits from '../../images/Accounting and Bookkeeping Services/5.Benefits.PNG';
import objectiveToDeliver from '../../images/Accounting and Bookkeeping Services/6.ObjectiveToDeliver.PNG';
import packageTitle from '../../images/Accounting and Bookkeeping Services/7.PackageTitle.PNG';
import servicePackageStart from '../../images/Accounting and Bookkeeping Services/8.ServicePackage.PNG';
import servicePackageMid from '../../images/Accounting and Bookkeeping Services/9.ServicePackage2.PNG';
import servicePackageEnd from '../../images/Accounting and Bookkeeping Services/10.ServicePackage3.PNG';
import contactUs from '../../images/Accounting and Bookkeeping Services/11.ContactUs.PNG';
import servingYou from '../../images/Accounting and Bookkeeping Services/12.ServingYou.PNG';

interface FinancialServicesProps {
    productOnly?: boolean
}


export default function FinancialServices(props: FinancialServicesProps) {
    const arrowStyles = {
        cursor: "pointer",
        pos: "absolute",
        top: "50%",
        w: "auto",
        mt: "-22px",
        p: "16px",
        color: "black",
        fontWeight: "bold",
        fontSize: "40px",
        transition: "0.6s ease",
        borderRadius: "0 3px 3px 0",
        userSelect: "none",
        _hover: {
            opacity: 0.8,
            bg: "gray",
        },
    } as const;

    const productOfferingSlides = [
        {
            img: whyOutsource,
        },
        {
            img: benefits,
        },
        {
            img: objectiveToDeliver,
        },
    ];

    const allSlides = [
        {
            img: title
        },
        {
            img: aboutUs,
        },
        {
            img: serviceOfferings,
        },
        {
            img: packageTitle,
        },
        {
            img: servicePackageStart,
        },
        {
            img: servicePackageMid
        },
        {
            img: servicePackageEnd
        },
        {
            img: contactUs,
        },
        {
            img: servingYou
        }
    ];

    const slides = props.productOnly ? productOfferingSlides: allSlides;

    const [currentSlide, setCurrentSlide] = useState(0);

    const slidesCount = slides.length;

    const prevSlide = () => {
        setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1));
    };
    const nextSlide = () => {
        setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1));
    };

    const carouselStyle = {
        transition: "all .5s",
        ml: `-${currentSlide * 100}%`,
    };

    return (
        <Flex
            w="full"
            bg="#edf3f8"
            _dark={{bg: "#3e3e3e"}}
            p={10}
            alignItems="center"
            justifyContent="center"
        >
            <Flex w="full" overflow="hidden" pos="relative">
                <Flex h="100%" w="full" {...carouselStyle}>
                    {slides.map((slide, sid) => (
                        <Box key={`slide-${sid}`} boxSize="full" shadow="md" flex="none">
                            <Text
                                color="white"
                                fontSize="xs"
                                p="8px 12px"
                                pos="absolute"
                                top="0"
                            >
                                {sid + 1} / {slidesCount}
                            </Text>
                            <Image
                                src={slide.img}
                                alt="carousel image"
                                boxSize="full"
                                backgroundSize="cover"
                            />
                        </Box>
                    ))}
                </Flex>
                <Text {...arrowStyles} left="0" onClick={prevSlide}>
                    &#10094;
                </Text>
                <Text {...arrowStyles} right="0" onClick={nextSlide}>
                    &#10095;
                </Text>
            </Flex>
        </Flex>
    );
};
