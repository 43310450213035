import React, { useState } from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import ResidentialSingapore from '../../images/Architectural Design Portfolio/ResidentialSingapore.PNG';
import ResidentialDesignProjects from '../../images/Architectural Design Portfolio/ResidentialDesignProjects.PNG';
import ExteriorDesignSamples from '../../images/Architectural Design Portfolio/ExteriorDesignSamples.PNG';
import ExteriorDesignSamplesTownhouse from '../../images/Architectural Design Portfolio/ExteriorDesignSamplesTownhouse.PNG';
import InteriorDesignSamples from '../../images/Architectural Design Portfolio/InteriorDesignSamples.PNG';
import InteriorDesignSamplesNorthAndCebu from '../../images/Architectural Design Portfolio/InteriorDesignSamplesNorthAndCebu.PNG';
import CommercialProjects from '../../images/Architectural Design Portfolio/CommercialProjects.PNG';
import HotelAndPorts from '../../images/Architectural Design Portfolio/HotelAndPorts.PNG';
import Condominiums from '../../images/Architectural Design Portfolio/Condominiums.PNG';
import CondoUnits from '../../images/Architectural Design Portfolio/CondoUnits.PNG';
import ResidentialInterior from '../../images/Architectural Design Portfolio/ResidentialInterior.PNG';
import Outsourcing from '../../images/Architectural Design Portfolio/Outsourcing.PNG';
import OutsourcingPlans from '../../images/Architectural Design Portfolio/OutsourcingPlans.PNG';
import OutsourcingProjects from '../../images/Architectural Design Portfolio/OutsourcingProjects.PNG';
import OutsourcingRoofFile from '../../images/Architectural Design Portfolio/OutsourcingRoofFile.PNG';
import RevitDetailing from '../../images/Architectural Design Portfolio/RevitDetailing.PNG';
import StructuralDetailing from '../../images/Architectural Design Portfolio/StructuralDetailing.PNG';
import JoineryDetailing from '../../images/Architectural Design Portfolio/JoineryDetailing.PNG';
import JoineryDetailingPerspective from '../../images/Architectural Design Portfolio/JoineryDetailingPerspective.PNG';
import JoineryDetailingPerspectiveWithDoors from '../../images/Architectural Design Portfolio/JoineryDetailingPerspectiveWithDoors.PNG';
import JoineryCounterDetails from '../../images/Architectural Design Portfolio/JoineryCounterDetails.PNG';


export default function DesignPortfolio() {
    const arrowStyles = {
        cursor: "pointer",
        pos: "absolute",
        top: "50%",
        w: "auto",
        mt: "-22px",
        p: "16px",
        color: "white",
        fontWeight: "bold",
        fontSize: "40px",
        transition: "0.6s ease",
        borderRadius: "0 3px 3px 0",
        userSelect: "none",
        _hover: {
            opacity: 0.8,
            bg: "black",
        },
    } as const;

    const slides = [
        {
            img: ResidentialSingapore
        },
        {
            img: ResidentialDesignProjects,
        },
        {
            img: ExteriorDesignSamples,
        },
        {
            img: ExteriorDesignSamplesTownhouse,
        },
        {
            img: InteriorDesignSamples,
        },
        {
            img: InteriorDesignSamplesNorthAndCebu,
        },
        {
            img: CommercialProjects,
        },
        {
            img: HotelAndPorts,
        },
        {
            img: Condominiums,
        },
        {
            img: CondoUnits,
        },
        {
            img: ResidentialInterior,
        },
        {
            img: Outsourcing,
        },
        {
            img: OutsourcingPlans,
        },
        {
            img: OutsourcingProjects,
        },
        {
            img: OutsourcingRoofFile,
        },
        {
            img: RevitDetailing,
        },
        {
            img: StructuralDetailing,
        },
        {
            img: JoineryDetailing,
        },
        {
            img: JoineryCounterDetails,
        },
        {
            img: JoineryDetailingPerspective,
        },
        {
            img: JoineryDetailingPerspectiveWithDoors,
        },
    ];

    const [currentSlide, setCurrentSlide] = useState(0);

    const slidesCount = slides.length;

    const prevSlide = () => {
        setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1));
    };
    const nextSlide = () => {
        setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1));
    };

    const carouselStyle = {
        transition: "all .5s",
        ml: `-${currentSlide * 100}%`,
    };

    return (
        <Flex
            w="full"
            bg="#edf3f8"
            _dark={{ bg: "#3e3e3e" }}
            p={10}
            alignItems="center"
            justifyContent="center"
        >
            <Flex w="full" overflow="hidden" pos="relative">
                <Flex h="800px" w="full" {...carouselStyle}>
                    {slides.map((slide, sid) => (
                        <Box key={`slide-${sid}`} boxSize="full" shadow="md" flex="none">
                            <Text
                                color="white"
                                fontSize="xs"
                                p="8px 12px"
                                pos="absolute"
                                top="0"
                            >
                                {sid + 1} / {slidesCount}
                            </Text>
                            <Image
                                src={slide.img}
                                alt="carousel image"
                                boxSize="full"
                                backgroundSize="cover"
                            />
                        </Box>
                    ))}
                </Flex>
                <Text {...arrowStyles} left="0" onClick={prevSlide}>
                    &#10094;
                </Text>
                <Text {...arrowStyles} right="0" onClick={nextSlide}>
                    &#10095;
                </Text>
            </Flex>
        </Flex>
    );
};
