import {
        Container,
        Flex,
        Box,
        Heading,
        Text,
        IconButton,
        Button,
        VStack,
        HStack,
        Wrap,
        WrapItem,
        FormControl,
        FormLabel,
        Input,
        InputGroup,
        InputLeftElement,
        Textarea,
        useToast
} from '@chakra-ui/react';

import {
        MdPhone,
        MdEmail,
        MdFacebook,
        MdOutlineEmail, MdOutlinePhone,
} from 'react-icons/md';
import { BsPerson } from 'react-icons/bs';
import {useState} from "react";

interface SignUpProps {
        signupType: string
}

export default function SignUp(props: SignUpProps) {

        const signupType = props.signupType;
        const contactEmail = signupType === "financial" ? "bookkeeping@platforumsolutions.com" : "archdesign@platforumsolutions.com";
        const [name, setName] = useState("");
        const [email, setEmail] = useState("");
        const [phoneNumber, setPhoneNumber] = useState("");
        const [message, setMessage] = useState("");

        const toast = useToast();


        const handleSubmit = async (e: any) => {
                e.preventDefault();

                let res = await fetch("https://api.platforumsolutions.com/signup-messages", {
                        method: "POST",
                        headers: {
                                "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                                name: name,
                                email: email,
                                phoneNumber: phoneNumber,
                                message: message,
                                type: signupType
                        }),
                });

                await res.json();

                if (res.status === 201) {
                        setName("");
                        setEmail("");
                        setPhoneNumber("");
                        setMessage("");
                        toast({
                                title: 'Message Created.',
                                description: "Our team will be reaching out soon.",
                                status: 'success',
                                duration: 7000,
                                isClosable: true,
                        })
                } else {
                        toast({
                                title: 'Message Creation Failed.',
                                description: "Oops! This is embarrassing. Please try your message again or contact our team via email while we sort out this issue.",
                                status: 'error',
                                duration: 9000,
                                isClosable: true,
                        })
                }

        };
        return (
            <Container bg="rgba(255, 255, 255, 0.24)" maxW="full" mt={0} centerContent overflow="hidden">
                    <Flex>
                            <Box
                                bg="orange.600"
                                color="white"
                                borderRadius="lg"
                                m={{ sm: 4, md: 16, lg: 10 }}
                                p={{ sm: 5, md: 5, lg: 16 }}>
                                    <Box p={4}>
                                            <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
                                                    <WrapItem>
                                                            <Box>
                                                                    <Heading>Getting Started</Heading>
                                                                    <Text mt={{ sm: 3, md: 3, lg: 5 }}>
                                                                             Fill up the form to connect with the PlatForum team. <br/><br/>
                                                                             The {signupType} services team is also available by phone <br/>
                                                                             to answer any inquiries and help getting you started.
                                                                    </Text>
                                                                    <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                                                                            <VStack pl={0} spacing={3} alignItems="flex-start">
                                                                                    <Button
                                                                                        size="md"
                                                                                        height="48px"
                                                                                        width="300px"
                                                                                        variant="ghost"
                                                                                        color="#DCE2FF"
                                                                                        _hover={{ border: '2px solid #1C6FEB' }}
                                                                                        leftIcon={<MdPhone color="#1970F1" size="20px" />}>USA Line +1 954 915 4833
                                                                                    </Button>
                                                                                    <Button
                                                                                        size="md"
                                                                                        height="48px"
                                                                                        width="300px"
                                                                                        variant="ghost"
                                                                                        color="#DCE2FF"
                                                                                        _hover={{ border: '2px solid #1C6FEB' }}
                                                                                        leftIcon={<MdPhone color="#1970F1" size="20px" />}>Australia Line +1 954 915 4833
                                                                                    </Button>
                                                                                    <Button
                                                                                        size="md"
                                                                                        height="48px"
                                                                                        width="300px"
                                                                                        variant="ghost"
                                                                                        color="#DCE2FF"
                                                                                        _hover={{ border: '2px solid #1C6FEB' }}
                                                                                        leftIcon={<MdEmail color="#1970F1" size="20px" />}>
                                                                                            { contactEmail }
                                                                                    </Button>
                                                                            </VStack>
                                                                    </Box>
                                                                    <HStack
                                                                        mt={{ lg: 10, md: 10 }}
                                                                        spacing={5}
                                                                        px={5}
                                                                        alignItems="flex-start">
                                                                            <a href="https://www.facebook.com/profile.php?id=100094703406243">
                                                                                    <IconButton
                                                                                        aria-label="facebook"
                                                                                        variant="ghost"
                                                                                        size="lg"
                                                                                        isRound={true}
                                                                                        _hover={{ bg: '#0D74FF' }}
                                                                                        icon={<MdFacebook size="28px" />}
                                                                                    />
                                                                            </a>
                                                                            {/*<a href="https://www.linkedin.com/in/travel-mate-711a6616a">*/}
                                                                            {/*        <IconButton*/}
                                                                            {/*            aria-label="linkedin"*/}
                                                                            {/*            variant="ghost"*/}
                                                                            {/*            size="lg"*/}
                                                                            {/*            isRound={true}*/}
                                                                            {/*            _hover={{ bg: '#0D74FF' }}*/}
                                                                            {/*            icon={<BsLinkedin size="28px" />}*/}
                                                                            {/*        />*/}
                                                                            {/*</a>*/}
                                                                            {/*<a href="https://twitter.com/Intl_TravelMate">*/}
                                                                            {/*        <IconButton*/}
                                                                            {/*            aria-label="twitter"*/}
                                                                            {/*            variant="ghost"*/}
                                                                            {/*            size="lg"*/}
                                                                            {/*            isRound={true}*/}
                                                                            {/*            _hover={{ bg: '#0D74FF' }}*/}
                                                                            {/*            icon={<BsTwitter size="28px" />}*/}
                                                                            {/*        />*/}
                                                                            {/*</a>*/}
                                                                    </HStack>
                                                            </Box>
                                                    </WrapItem>
                                                    <WrapItem>
                                                            <Box bg="white" borderRadius="lg">
                                                                    <Box m={8} color="#0B0E3F">
                                                                            <form onSubmit={handleSubmit}>
                                                                                    <FormControl
                                                                                        id="name"
                                                                                    >
                                                                                            <FormLabel>Your Name</FormLabel>
                                                                                            <InputGroup borderColor="#E0E1E7">
                                                                                                    <InputLeftElement
                                                                                                        pointerEvents="none"
                                                                                                        children={<BsPerson color="gray.800" />}
                                                                                                    />
                                                                                                    <Input value={name || ""}
                                                                                                           type="text"
                                                                                                           size="md"
                                                                                                           onChange={(e: any) => setName(e.target.value)}
                                                                                                    />
                                                                                            </InputGroup>
                                                                                    </FormControl>
                                                                                    <FormControl
                                                                                        mt={2}
                                                                                    >
                                                                                            <FormLabel>Email</FormLabel>
                                                                                            <InputGroup borderColor="#E0E1E7">
                                                                                                    <InputLeftElement
                                                                                                        pointerEvents="none"
                                                                                                        children={<MdOutlineEmail color="gray.800" />}
                                                                                                    />
                                                                                                    <Input value={email || ""}
                                                                                                           type="email"
                                                                                                           size="md"
                                                                                                           onChange={(e: any) => setEmail(e.target.value)}
                                                                                                    />
                                                                                            </InputGroup>
                                                                                    </FormControl>
                                                                                    <FormControl
                                                                                        mt={2}
                                                                                        id="phone-number"
                                                                                    >
                                                                                            <FormLabel>Phone Number</FormLabel>
                                                                                            <InputGroup borderColor="#E0E1E7">
                                                                                                    <InputLeftElement
                                                                                                        pointerEvents="none"
                                                                                                        children={<MdOutlinePhone color="gray.800" />}
                                                                                                    />
                                                                                                    <Input value={phoneNumber || ""}
                                                                                                           type="text"
                                                                                                           size="md"
                                                                                                           onChange={(e: any) => setPhoneNumber(e.target.value)}
                                                                                                    />
                                                                                            </InputGroup>
                                                                                    </FormControl>
                                                                                    <FormControl
                                                                                        mt={2}
                                                                                        id="message"
                                                                                    >
                                                                                            <FormLabel>Message</FormLabel>
                                                                                            <Textarea
                                                                                                borderColor="gray.300"
                                                                                                _hover={{
                                                                                                        borderRadius: 'gray.300',
                                                                                                }}
                                                                                                placeholder="message"
                                                                                                value={message || ""}
                                                                                                onChange={(e: any) => setMessage(e.target.value)}
                                                                                            />
                                                                                    </FormControl>
                                                                                    <Button
                                                                                        mt={4}
                                                                                        variant="solid"
                                                                                        bg="#0D74FF"
                                                                                        color="white"
                                                                                        _hover={{}}
                                                                                        type="submit">
                                                                                            Send Message
                                                                                    </Button>
                                                                            </form>
                                                                    </Box>
                                                            </Box>
                                                    </WrapItem>
                                            </Wrap>
                                    </Box>
                            </Box>
                    </Flex>
            </Container>
        );
}