import React, { useState } from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import GlassGridBuilding from '../../images/Architectural Design/GlassGridBuilding.jpg';
import ModernPoolHouse from '../../images/Architectural Design/ModernPoolHouse.jpg';
import ModernWoods from '../../images/Architectural Design/ModernWoods.jpg';
import ModernVilla from '../../images/Architectural Design/ModernVilla.jpg';
import WhiteOpenInterior from '../../images/Architectural Design/WhiteOpenInterior.jpg';
import BedroomInterior from '../../images/Architectural Design/BedroomInterior.jpg';
import Courtyard from '../../images/Architectural Design/Courtyard.jpg';

export default function DesignCarousel() {
    const arrowStyles = {
        cursor: "pointer",
        pos: "absolute",
        top: "50%",
        w: "auto",
        mt: "-22px",
        p: "16px",
        color: "black",
        fontWeight: "bold",
        fontSize: "40px",
        transition: "0.6s ease",
        borderRadius: "0 3px 3px 0",
        userSelect: "none",
        _hover: {
            opacity: 0.8,
            bg: "gray",
        },
    } as const;

    const slides = [
        {
            img: ModernPoolHouse
        },
        {
            img: GlassGridBuilding
        },
        {
            img: ModernWoods
        },
        {
            img: ModernVilla
        },
        {
            img: Courtyard
        },
        {
            img: WhiteOpenInterior
        },
        {
            img: BedroomInterior
        },
    ];

    const [currentSlide, setCurrentSlide] = useState(0);

    const slidesCount = slides.length;

    const prevSlide = () => {
        setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1));
    };
    const nextSlide = () => {
        setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1));
    };

    const carouselStyle = {
        transition: "all .5s",
        ml: `-${currentSlide * 100}%`,
    };

    return (
        <Flex
            w="full"
            bg="#edf3f8"
            _dark={{ bg: "#3e3e3e" }}
            p={10}
            alignItems="center"
            justifyContent="center"
        >
            <Flex w="full" overflow="hidden" pos="relative">
                <Flex h="800px" w="full" {...carouselStyle}>
                    {slides.map((slide, sid) => (
                        <Box key={`slide-${sid}`} boxSize="full" shadow="md" flex="none">
                            <Text
                                color="white"
                                fontSize="xs"
                                p="8px 12px"
                                pos="absolute"
                                top="0"
                            >
                                {sid + 1} / {slidesCount}
                            </Text>
                            <Image
                                src={slide.img}
                                alt="carousel image"
                                boxSize="full"
                                backgroundSize="cover"
                            />
                        </Box>
                    ))}
                </Flex>
                <Text {...arrowStyles} left="0" onClick={prevSlide}>
                    &#10094;
                </Text>
                <Text {...arrowStyles} right="0" onClick={nextSlide}>
                    &#10095;
                </Text>
            </Flex>
        </Flex>
    );
};
