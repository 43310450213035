import { ChakraProvider } from "@chakra-ui/react";
import FinancialServicesNavBar from "../../Components/FinancialServicesNavBar/FinancialServicesNavBar";
import React from "react";
import Footer from "../../Components/Footer/Footer";
import FinancialServicesCallToAction from "../../Components/FinancialCallToAction/FinancialCallToAction";

const FinancialServiceCallToAction = () => {
    return (
        <ChakraProvider>
                <FinancialServicesNavBar/>
                <FinancialServicesCallToAction/>
                <Footer/>
        </ChakraProvider>
    )
}

export default FinancialServiceCallToAction;