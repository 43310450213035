import { ChakraProvider } from "@chakra-ui/react";
import FinancialServicesNavBar from "../../Components/FinancialServicesNavBar/FinancialServicesNavBar";
import React from "react";
import SignUp from "../../Components/SignUp/SignUp";
import Footer from "../../Components/Footer/Footer";

const FinancialGettingStarted = () => {
    return (
        <ChakraProvider>
            <FinancialServicesNavBar/>
            <SignUp signupType={"financial"}/>
            <Footer/>
        </ChakraProvider>
    )
}

export default FinancialGettingStarted;